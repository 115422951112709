import { render, staticRenderFns } from "./matriz.vue?vue&type=template&id=15b2c3af&scoped=true&"
import script from "./matriz.vue?vue&type=script&lang=js&"
export * from "./matriz.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b2c3af",
  null
  
)

export default component.exports