<template>
    <section class="compras-matriz px-3">
        <navbar-admin icon="icon-shopping"  />
        <titulo-divisor titulo="Matriz de ordenes de compra">
            <div class="row">
                <div class="col-auto px-1">
                    <el-input v-model="filtroGruposOcs.buscar" placeholder="Buscar matriz OC" size="small" @keyup.native.enter="searchOcs"/>
                </div>
                <div v-if="filtersCount == 0" class="col-auto my-auto px-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filtersCount > 0" class="col-auto filtro-contador my-auto px-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12"> {{ filtersCount }} </p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="clearAllFilters" />
                        </div>
                    </div>
                </div>
                <div class="col-auto px-1">
                    <button class="btn btn-general f-12 px-3"
                        :class="permitAction('compras.crear.matriz.oc') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('compras.crear.matriz.oc', createMatrizOc)"
                    >
                        Crear matriz OC
                    </button>
                </div>
            </div>
        </titulo-divisor>
        <matriz-oc ref="matrizOcs"/>
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 mb-4">
                    <p class="input-label-top">Oportunidad</p>
                    <el-select v-model="filtroGruposOcs.id_oportunidad" filterable clearable placeholder="Seleccionar oportunidad" size="small" class="w-100 ">
                        <el-option v-for="item in filtrosGruposOcs.oportunidades" :key="item.id" :label="item.nombre" :value="item.id">
                            <p class="tres-puntos" style="max-width: 200px !important;">{{item.nombre}}</p>
                        </el-option>
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Producto</p>
                    <el-select v-model="filtroGruposOcs.id_producto" filterable clearable placeholder="Seleccionar producto" size="small" class="w-100">
                        <el-option v-for="item in filtrosGruposOcs.productos" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proveedor</p>
                    <el-select v-model="filtroGruposOcs.id_proveedor" filterable clearable placeholder="Seleccionar proveedor" size="small" class="w-100">
                        <el-option v-for="item in filtrosGruposOcs.proveedores" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Bodega</p>
                    <el-select v-model="filtroGruposOcs.id_bodega" filterable clearable placeholder="Seleccionar bodega" size="small" class="w-100">
                        <el-option v-for="item in filtrosGruposOcs.bodega" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilters"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterMatriz"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-create-matriz ref="createMatrizOc" />
    </section>
</template>

<script>
import modalCreateMatriz from './partials/modalCreateMatriz.vue'
import matrizOc from './components/matrizOC.vue'
import { mapActions, mapGetters } from 'vuex';
export default {
    name:'matriz',
    components:{
        modalCreateMatriz,
        matrizOc,
    },
    data(){
        return{
            buscarListado: '',
            cantFiltros:0,
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                },
                {
                    value: 'Option2',
                    label: 'Option2'
                },
                {
                    value: 'Option3',
                    label: 'Option3'
                },
                {
                    value: 'Option4',
                    label: 'Option4'
                },
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            valueSelectProveedores: '',
            fechaInicio: '',
            fechaFinal: '',
            filters: {
                id_oportunidad: null,
                id_producto: null,
                id_proveedor: null,
                id_bodega: null,
            },
            months:[ 'Enero', 'Febrero', 'Marzo', 'Abril' ],
        }
    },
    computed:{
        ...mapGetters({
            filtrosGruposOcs: 'compras/ordenes_compra/filtrosGruposOcs',
            filtroGruposOcs: 'compras/ordenes_compra/filtroGruposOcs'
        }),
        filtersCount(){
            const { id_oportunidad, id_producto, id_proveedor, id_bodega } = this.filters;
            let count = 0;
            if(id_oportunidad) count++;
            if(id_producto) count++;
            if(id_proveedor) count++;
            if(id_bodega) count++;
            return count;
        }
    },
    async created(){
        await this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'compras.matrix',
        ]);
        this.getSelects(['Action_get_select_monedas'])
        await this.Action_get_filtros_grupos_ordenes_comrpas()
    },
    async mounted(){
    },
    methods:{
        ...mapActions({
            getSelects: 'selects/selects/sync',
            Action_get_filtros_grupos_ordenes_comrpas: 'compras/ordenes_compra/Action_get_filtros_grupos_ordenes_comrpas',
            Action_clear_grupos_ocs: 'compras/ordenes_compra/Action_clear_grupos_ocs',
            obtenerGrupos: 'compras/ordenes_compra/Action_get_grupos'
        }),
        createMatrizOc(){
            this.$refs.createMatrizOc.toggle()
        },
        async clearFilters(){
            this.Action_clear_grupos_ocs()
            const dates = this.$refs.matrizOcs.dates
            await this.obtenerGrupos({meses: dates.map(e => e.fecha)})
            this.$refs.abrirModalFiltros.toggle()
        },
        clearAllFilters(){
            this.Action_clear_grupos_ocs()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filterMatriz(){
            const dates = this.$refs.matrizOcs.dates
            await this.obtenerGrupos({meses: dates.map(e => e.fecha)})
            this.$refs.abrirModalFiltros.toggle()
        },
        async searchOcs(){
            const dates = this.$refs.matrizOcs.dates
            await this.obtenerGrupos({meses: dates.map(e => e.fecha)})
        },
        goTo(){
            this.$router.push({ name: 'compras.ordenes' })
        }
    }

}
</script>

<style lang="scss" scoped>
</style>