<template>
    <div class="item-op f-12 bg-white shadow29 br-5 pt-1 my-2 cr-pointer" @click="goTo()">
        <div class="row border-bottom pb-1 mb-2 mx-0 d-none">
            <div class="col-auto">
                <p class="text-general font-weight-bold">Oportunidad:</p>
            </div>
            <div class="col-5 px-0">
                <p class="f-600 lh-15 tres-puntos-2">{{oportunity.oportunidad}}</p>
            </div>
        </div>
        <div class="row border-bottom pb-1 mb-2 mx-0 px-3">
            <p class="text-general font-weight-bold">Oportunidad:</p>
            <p class="f-600 lh-15 tres-puntos">{{ oportunity.oportunidad }}</p>
        </div>
        <div class="row mx-0 d-none">
            <div class="col-auto">
                <p class="text-general font-weight-bold">Producto:</p>
            </div>
            <div class="col-7">
                <p class="f-600 lh-15 tres-puntos-2">{{oportunity.producto}}</p>
            </div>
        </div>
        <div class="row  mx-0 px-3">
            <p class="text-general font-weight-bold">Producto:</p>
            <p class="f-600 lh-15 tres-puntos">{{oportunity.producto}}</p>
        </div>
        <div class="row justify-content-center mx-0 my-2">
            <div class="col-9">
                <p class="text-general">OC:</p>
            </div>
            <div class="col-2 text-center">
                <p>{{oportunity.oc}}</p>
            </div>
        </div>
        <div class="row justify-content-center mx-0 my-2">
            <div class="col-9">
                <p class="text-general">Proveedores:</p>
            </div>
            <div class="col-2 text-center">
                <p>{{oportunity.proveedores}}</p>
            </div>
        </div>
        <div class="row justify-content-center mx-0 my-2">
            <div class="col-9">
                <p class="text-general">Bodegas:</p>
            </div>
            <div class="col-2 text-center">
                <p>{{oportunity.bodegas}}</p>
            </div>
        </div>
        <div class="indicator bg-general br-b-5" />
    </div>
    
</template>

<script>
import { MatrizListFactory } from '~/domain/entities/compras/matriz/factories/MatrizListFactory'
export default {
    props: {
        oportunity: {
            type: Object,
            default: () => MatrizListFactory.instantiateEmpty()
        }
    },
    methods: {
        goTo(){
            this.$try(() => {
                this.navigateTo({ name: `compras.main`, params: {id: this.oportunity.id}})
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .indicator{
            height: 7px;
    }
    .tres-puntos-2{
        min-height: 32px;
        max-height: 32px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>