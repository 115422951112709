<template>
    <div class="matriz-oc">
        <div class="row">
            <p class="col-12 text-general f-22 font-weight-bold text-center"> {{ getYear }} </p>
        </div>
        <div class="row mt-3 justify-content-center">
            <div class="col-auto icon-option px-0">
                <i class="icon-arrow-left-drop-circle f-30 cr-pointer" @click="subtractMonth" />
            </div>
            <div class="col-auto">
                <div class="row justify-content-center">
                    <div v-for="(data, idx) in dates" :key="idx" class="col-auto px-2 mb-3">
                        <div class="month-details bg-f6 br-5">
                            <div class="month-name bg-f6 shadow29 br-5 f-14 d-middle-center text-general font-weight-bold mb-2">
                                {{ data.mes }}
                            </div>
                            <div class="list-ops p-2 br-5 overflow-auto scroll-none">
                                <card-ops v-for="(oportunidad, index) in grupos[dates.map(e => e.fecha)[idx]]" :key="index" :oportunity="oportunidad"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto icon-option px-0">
                <i class="icon-arrow-right-drop-circle f-30 cr-pointer" @click="addMonth"  />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

//Components
import cardOps from './cardOps.vue'
import moment from 'moment'


export default {
    name: 'matrizOc',
    components:{
        cardOps
    },
    data(){
        return {
            dates: []
        }
    },
    computed: {
        ...mapGetters({
            grupos: 'compras/ordenes_compra/grupos'
        }),
        getYear(){
            return moment(this.dates[0].fecha).year()
        }
    },
    watch: {
        async dates(val) {
            await this.obtenerGrupos({meses: val.map(e => e.fecha)})
        }
    },
    methods: {
        ...mapActions({
            obtenerGrupos: 'compras/ordenes_compra/Action_get_grupos'
        }),
        getDates(){
            const datePrev = moment(`${moment().year()}-${moment().month()+1}-01`).subtract(2, 'M')
            let dates = []
            for (let i = 0; i < 4; i++) {
                datePrev.add(1, 'M')
                dates.push({fecha: datePrev.format('YYYY-MM-DD'), mes: datePrev.format('MMMM') })
            }
            this.dates = dates
        },
        subtractMonth(){
            this.dates.pop()
            const date = moment(this.dates[0].fecha).subtract(1, 'M')
            this.dates.unshift({ fecha: date.format('YYYY-MM-DD'), mes: date.format('MMMM') })
        },
        addMonth(){
            this.dates.shift()
            const date = moment(this.dates[this.dates.length-1].fecha).add(1, 'M')
            this.dates.push({ fecha: date.format('YYYY-MM-DD'), mes: date.format('MMMM') })
        }
    },
    async created(){
        this.getDates()
        await this.obtenerGrupos({meses: this.dates.map(e => e.fecha)})
    }
}
</script>

<style lang="scss" scoped>
.matriz-oc{
    background: #fff;
    .month-details{
        width: 247px;
        .month-name{
            height: 46px;
        }
        .day-name{
            //width: 150px;
            height: 35px;
        }
    }
    .day-name{
        width: 247px;
        height: 46px;
    }
    .list-ops{
            height: 570px;
    }
}
</style>